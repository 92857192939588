import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import PageTitle from "./PageTitle";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ pt:"120px", mt: 0, mb: 0, minHeight: "calc(100vh - 360px)" }}>
      <Box my={0} textAlign={"left"}>
        <PageTitle title="Privacy Policy"></PageTitle>
        <Typography my={4} variant="subtitle1" gutterBottom>
          Effective Date: <b>Aug 11, 2024</b>
        </Typography>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to www.marssoftech.com (the "Website"), operated by Mars Softech ("we," "us," or "our"). We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and
            safeguard your information when you visit our Website.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We may collect the following types of information:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Personal Information:</strong> Name, email address, phone number, mailing address, and any other information you provide voluntarily.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Usage Data:</strong> Information about how you interact with our Website, including IP address, browser type, and pages visited.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Cookies:</strong> We may use cookies to enhance your experience and gather information about your usage of the Website.
              </Typography>
            </li>
          </ul>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use the information we collect to:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">Provide, operate, and maintain the Website.</Typography>
            </li>
            <li>
              <Typography variant="body1">Improve your experience and customize content.</Typography>
            </li>
            <li>
              <Typography variant="body1">Respond to your inquiries and provide customer support.</Typography>
            </li>
            <li>
              <Typography variant="body1">Send you updates, promotional materials, or other communications.</Typography>
            </li>
          </ul>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            4. Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We do not share your personal information with third parties except as necessary to:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">Comply with legal obligations.</Typography>
            </li>
            <li>
              <Typography variant="body1">Protect and defend our rights and property.</Typography>
            </li>
            <li>
              <Typography variant="body1">Provide services through third-party providers under strict confidentiality agreements.</Typography>
            </li>
          </ul>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            5. Security
          </Typography>
          <Typography variant="body1" paragraph>
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, please note that no method of transmission over the internet is entirely secure.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">Access and update your personal information.</Typography>
            </li>
            <li>
              <Typography variant="body1">Request the deletion of your personal data.</Typography>
            </li>
            <li>
              <Typography variant="body1">Opt-out of marketing communications.</Typography>
            </li>
          </ul>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            7. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography variant="body1">
            Mars Softech
            <br />
            [Jaipur]
            <br />
            <Link href="mailto:[Your Email Address]">[Email Address]</Link>
            <br />
            [+91 XXXXXXXXX]
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
