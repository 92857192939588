import React from "react";
import { Container, Typography, Box, Link, Card, CardContent, ListItem, ListItemAvatar, Avatar, List, ListItemText, useMediaQuery } from "@mui/material";
import LocationIcon from "@mui/icons-material/LocationCityTwoTone";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import ContactForm from "./ContactForm";
import MarsLogo from "./MarsLogo";

export default function ContactUs() {
  const isAboveSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="md" sx={{ pt:"120px", mt: 0, mb: 0, minHeight: "calc(100vh - 360px)" }}>
      <Card sx={{ alignItems: "center", m: 0, padding: "20px !important", mb: 3, backgroundColor: "primary.main", /* borderRadius: 3, */ color: "primary.contrastText", textAlign: "left", display: "flex" }}>
        <CardContent sx={{ flex: 1 }}>
          <Typography variant="h4" paragraph>
            We’re here to help!
          </Typography>
          <Typography sx={{ m: 0 }} variant="body1" paragraph>
            Please feel free to contact us for support and inquiries using the form below. Your feedback is also important to us.
          </Typography>
        </CardContent>
        {/* {isAboveSm && <MarsLogo width={160} height={160}></MarsLogo>} */}
      </Card>
      <ContactForm></ContactForm>
      <Container maxWidth="xs" sx={{ mt: 0, mb: 0 }}>
        <Box my={3} textAlign={"left"}>
          <Typography variant="h5" fontWeight={"bold"}>
            Contact Details
          </Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "primary.main" }}>
                  <LocationIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography component="span" variant="body2" color="text.primary">
                      Our Office
                    </Typography>
                    <br />
                    <Typography component="span" sx={{ m: 0, mb: 1 }} paragraph variant="caption" color="text.primary">
                      (Monday-Friday, 8am - 8pm)
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography component="span" variant="body1" fontWeight={"bold"} color="primary.main">
                      Mars Softech
                    </Typography>
                    <br />
                    <Typography component="span" sx={{ m: 0 }} variant="body2" color="text.primary">
                      Address Line 1, Address Line 2, Jaipur, Rajasthan, India
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "primary.main" }}>
                  <PhoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="text.primary">
                      Our Phone
                    </Typography>
                    <Typography sx={{ m: 0, mb: 1 }} paragraph variant="caption" color="text.primary">
                      (Monday-Friday, 8am - 8pm)
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Link component="a" href="tel:+919XXXXXX123" variant="body1" fontWeight={"bold"} color="primary.main">
                      +91 9XXXXXX123
                    </Link>
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "primary.main" }}>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="text.primary">
                      Our Email Address
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Link component="a" href="mailto:support@marssoftech.com" variant="body1" fontWeight={"bold"} color="primary.main">
                      support@marssoftech.com
                    </Link>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Container>
    </Container>
  );
}
