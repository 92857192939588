export const API_BASE_URL = "https://api.marssoftech.com/v1";

//USER URLs
export const API_BASE_URL_USER = `${API_BASE_URL}`;

export const API_CONTACT_US_URL = `${API_BASE_URL_USER}/contact`;
export const API_SEND_OTP_URL = `${API_BASE_URL_USER}/sendOTP`;
export const API_SEND_EMAIL_OTP_URL = `${API_BASE_URL_USER}/sendEmailOTP`;
export const API_VERIFY_OTP_URL = `${API_BASE_URL_USER}/verifyOTP`;

//Error Messages
export const OTP_SENT_MESSAGE = "OTP Sent.";
export const FAILED_SEND_OTP = "Failed to send OTP.";
export const INVALID_OTP = "Invalid OTP.";
export const OTP_VALIDATION_MESSAGE = "OTP must be 6 digits.";
export const OTP_REQUIRED_MESSAGE = "OTP is required.";
export const FIRST_NAME_REQUIRED = "First name is required.";
export const MAXIMUM_CHARACTERS_ALLOWED = "Maximum 20 characters allowed.";
export const MINIMUM_CHARACTERS_ALLOWED = "Minimum 3 characters required.";
export const LAST_NAME_REQUIRED = "Last name is required.";
export const EMAIL_REQUIRED = "Email is required.";
export const INVALID_EMAIL_MESSAGE = "Invalid Email address.";
export const PINCODE_REQUIRED = "Pincode is required.";
export const PINCODE_VALIDATION_MESSAGE = "Pincode must be 6 digits.";
export const FAILED_TO_UPDATE_PROFILE = "Failed to update profile.";
export const FAILED_TO_UPDATE_KYC = "Failed to update KYC.";
export const FAILED_TO_ADD_BANK = "Failed to add bank.";
export const FAILED_TO_ADD_SIP = "Failed to add SIP.";
export const FAILED_TO_SEARCH_PINCODE = "Failed to search for pincode.";
export const MOBILE_NUMBER_REQUIRED = "Mobile number is required.";
export const MOBILE_NUMBER_VALIDATION_MESSAGE = "Mobile number must be 10 digits.";


export const IFSC_REQUIRED = "IFSC is required.";
export const INVALID_IFSC_MESSAGE = "Invalid IFSC code.";

export const BANK_REQUIRED = "Bank required.";
export const ACCOUNT_NAME_REQUIRED = "Name required for your convenience, so that you can easily identify the bank.";
export const ACCOUNT_NAME_MIN_LENGTH = "Account Name should be minium 4 letters.";
export const ACCOUNT_NAME_MAX_LENGTH = "Account Name should be maximum 16 letters.";
export const ACCOUNT_NUMBER_REQUIRED = "Account Number is required.";
export const ACCOUNT_NUMBER_MIN_LENGTH = "Account Number should be minium 11 digits.";
export const ACCOUNT_NUMBER_MAX_LENGTH = "Account Number should be maximum 16 digits.";
export const INVALID_ACCOUNT_NUMBER_MESSAGE = "Invalid Account Number.";