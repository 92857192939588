import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hero from "./components/Hero";
import FAQ from "./components/FAQ";
import AboutUs from "./components/AboutUs";

export default function LandingPage() {
  return (
    <>
      <CssBaseline />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <AboutUs />
        <Divider />
        <FAQ />
        <Divider />
      </Box>
    </>
  );
}
