import React from "react";
import { Typography, Box, Divider } from "@mui/material";

export default function PageTitle({ title, children, startComp: Start }) {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "bold" }} flexGrow={1} align="left" variant="h5">
          {title}{Start ? Start : undefined}
        </Typography>
        {children}
      </Box>
      <Divider sx={{ width: "100%", pt: 0.5, pb: 0.5 }} />
    </>
  );
}
