import React, { createContext, useCallback, useEffect, useState } from "react";
import { isExpired } from "react-jwt";

// Create the context
export const GlobalStateContext = createContext();

// Create a provider component
export default function GlobalStateProvider({ children }) {
  const [token, setToken] = useToken(localStorage.getItem("token"));
  const [userId, setUserId] = useUserId();
  const [mobile, setMobile] = useMobile();
  const [email, setEmail] = useEmail();
  const [pincode, setPincode] = usePincode();
  const [firstName, setFirstName] = useFirstName();
  const [dateOfBirth, setDateOfBirth] = useDateOfBirth();
  const [panCard, setPanCard] = usePanCard();
  const [lastName, setLastName] = useLastName();
  const [isKycVerified, setKycVerified] = useKycVerified(true);

  function useKycVerified(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (value === undefined || value === null) {
        setKycVerified(false);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        //console.trace("isKycVerified", newValue);
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function usePincode(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setPincode(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function usePanCard(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setPanCard(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }
  function useDateOfBirth(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setDateOfBirth(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }
  function useFirstName(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setFirstName(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function useLastName(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setLastName(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function useToken(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value || (value && isExpired(value))) {
        setToken(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        if (newValue) {
          localStorage.setItem("token", newValue);
        } else {
          localStorage.removeItem("token");

        }

        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function useMobile(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setMobile(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function useEmail(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setEmail(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  function useUserId(initValue) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        setUserId(null);
      }
    }, [value]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        return newValue;
      });
    }, []);

    return [value, customSetValue];
  }

  /*  
  function useCustomForLS(initValue, key) {
    const [value, setValue] = useState(initValue);

    useEffect(() => {
      if (!value) {
        let fnName = "set" + key.substr(0, 1).toUpperCase() + key.substr(1);
        console.log(eval(fnName));
        eval(fnName)(null);
      }
    }, [value, key]);

    const customSetValue = useCallback((newValue) => {
      setValue((_oldValue) => {
        if (newValue) {
          localStorage.setItem(key, newValue);
        } else {
          localStorage.removeItem(key);
        }

        return newValue;
      });
    }, []);

    return [value, customSetValue];
  } */

  return (
    <GlobalStateContext.Provider
      value={{ token, setToken, mobile, setMobile, email, setEmail, firstName, setFirstName, lastName, setLastName, pincode, setPincode, isKycVerified, setKycVerified, userId, setUserId, dateOfBirth, panCard }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
