import { Container, CssBaseline, Typography } from "@mui/material";
import React from "react";
import PageTitle from "./PageTitle";

export default function Disclaimer() {
  return (
    <Container maxWidth="md" sx={{ pt:"120px", mt: 0, mb: 0, minHeight: "calc(100vh - 360px)" }}>
      <CssBaseline />
      <PageTitle title="Disclaimer"></PageTitle>
      {/* <Typography my={0} variant="h4" fontWeight="bold" align="center"></Typography> */}
      <Typography my={4} textAlign={"left"}>
        Mars Softech (MS), along with its associated entities, including publishers, authors, agents, and employees, have made every effort to ensure the accuracy of the information presented on this website. However, they do not accept any
        responsibility for any loss, injury, or damage that may result from the use of or reliance on the information or advice provided. Users are responsible for their own use of the information on this site.
      </Typography>
      <Typography my={4} textAlign={"left"}>
        The documents and graphics on this website may contain technical inaccuracies or typographical errors. Information is subject to change, and Mars Softech (MS) may update or modify the products or content at any time without prior notice.
        Links to external sites are provided for convenience, and Mars Softech (MS) does not endorse or assume responsibility for the content of any linked site. The opinions expressed by individuals on this site are their own and do not
        necessarily represent the views of Mars Softech (MS).
      </Typography>
    </Container>
  );
}
