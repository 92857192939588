import { Container, CssBaseline, Stack, Typography } from "@mui/material";
import React from "react";

export default function AboutUs() {
  return (
    <Container id="about" maxWidth="md" sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "100dvh",
        /* width: "100vw",
        minWidth: "100vw",
        position: "relative",
        marginLeft: "calc(-50vw + 50%)", */
      }}>
      <CssBaseline />
      <Stack spacing={2} sx={{ alignItems: "center", width: "100%" }}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            fontWeight: "bold",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          Who We Are?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "text.secondary",
            width: { sm: "100%", md: "80%" },
          }}
        >
          Mars Softech (MS)
        </Typography>
      </Stack>
    </Container>
  );
}
