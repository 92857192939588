import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

/* import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"; */
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ToggleColorMode from "./ToggleColorMode";
import CompanyLogo from "./MarsLogo";
import { CssBaseline, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { AccountBalance, AccountCircle, ContactEmergency, ContactSupport, Home, Login, Logout, People, Person, Sip } from "@mui/icons-material";
import { GlobalStateContext } from "./GlobalStateProvider";

const pages = [
  { path: "/home", icon: <Home fontSize="small" />, section: "home", label: "Home" },
  { path: "/about", icon: <People fontSize="small" />, section: "about", label: "About" },
  { path: "/faq", icon: <ContactSupport fontSize="small" />, section: "faq", label: "FAQ" },
  { path: "/contact", icon: <ContactEmergency fontSize="small" />, label: "Contact Us" },
];
const settings = [
  { path: "/profile", icon: <Person fontSize="small" />, label: "Edit Profile" },
  { path: "/my-sips/1", icon: <Sip fontSize="small" />, label: "My SIPs" },
  { path: "/my-banks", icon: <AccountBalance fontSize="small" />, label: "My Bank Accounts" },
  {
    path: "/logout",
    icon: <Logout fontSize="small" />,
    label: "Logout",
  },
];

function AppAppBar({ mode, toggleColorMode }) {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
  const buildDate = process.env.REACT_APP_BUILD_DATE;

  const { token, firstName } = useContext(GlobalStateContext);

  useEffect(() => {
    if (activePage !== location.pathname) {
      setActivePage(location.pathname);
    }
  }, [location, activePage]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (page, checkForSection) => {
    let sectionId = page.section;
    const offset = 0;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement && checkForSection) {
      const targetScroll = sectionElement.offsetTop - offset;
      /* sectionElement.scrollIntoView({ behavior: "smooth" }); */
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    } else {
      navigate(page.path);
      setTimeout(() => {
        scrollToSection(page, true);
      }, 0);
    }
  };
  const navigateTo = (page) => {
    if (page.props) {
      navigate(page.path, { state: { ...page.props } });
    } else {
      navigate(page.path);
    }
    setOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: "transparent", backgroundImage: "none", mt: 2 }}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            paddingRight: token ? "4px !important" : undefined,
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "0px 0px 16px 16px",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            bgcolor: "hsla(220, 60%, 99%, 0.25)",
            /* boxShadow: "0 1px 2px rgba(255,202,41, 0.05), 0 2px 12px rgba(255,202,41, 0.5)", */
            ...theme.applyStyles("dark", {
              bgcolor: "hsla(220, 0%, 0%, 0.5)",
              /* boxShadow: "0 1px 2px rgba(255,202,41, 0.05), 0 2px 12px rgba(255,202,41, 0.3)", */
            }),
          })}
        >
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
            <CompanyLogo />

            <Box sx={{ ml: 2, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <Fragment key={page.section + "_tb"}>
                  <Button
                    selected={activePage === page.path}
                    sx={{ minWidth: "initial", p: 1 }}
                    variant="text"
                    color={activePage === page.path ? "primary" : "secondary"}
                    size="small"
                    component="button"
                    onClick={page.section ? () => scrollToSection(page) : () => navigateTo(page)}
                  >
                    {page.label}
                  </Button>
                  {index < pages.length - 1 ? <Divider sx={{ height: 28, m: 1 }} orientation="vertical" /> : null}
                </Fragment>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <ToggleColorMode sx={{ display: { xs: "none", sm: "flex" } }} data-screenshot="toggle-mode" mode={mode} toggleColorMode={toggleColorMode} />
            {!token ? (
              <Button sx={{ display: activePage === "/login" ? "none" : "" }} color="primary" variant="contained" size="small" component="a" href="https://app.marssoftech.com/login">
                <nobr>Sign In/Up</nobr>
              </Button>
            ) : (
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                {token ? <AccountCircle fontSize="large"></AccountCircle> : <MenuIcon />}
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <ToggleColorMode sx={{ display: { xs: "none", sm: "flex" } }} mode={mode} toggleColorMode={toggleColorMode} />
            {!token && (
              <Button sx={{ display: activePage === "/login" ? "none" : "" }} color="primary" variant="contained" size="small" component="a" href="https://app.marssoftech.com/login">
                <nobr>Sign In/Up</nobr>
              </Button>
            )}
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              {token ? <AccountCircle fontSize="large"></AccountCircle> : <MenuIcon />}
            </IconButton>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ minWidth: 240, maxWidth: 240, p: 0, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  {token && (
                    <Typography sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} fontWeight="bold" noWrap>
                      {firstName ? `Hi, ${firstName}` : undefined}&nbsp;👋
                    </Typography>
                  )}
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 0 }} />
                {token &&
                  settings.map((setting) => (
                    <MenuItem selected={activePage === setting.path} sx={{ py: 1.5, ...setting.props }} data-key={setting.path} key={setting.path + "_drawer"} onClick={() => navigateTo(setting)}>
                      <ListItemIcon>{setting.icon}</ListItemIcon>
                      <ListItemText>{setting.label}</ListItemText>
                    </MenuItem>
                  ))}
                <Divider sx={{ my: 3, display: token ? "block" : "none" }} />
                {!token && (
                  <>
                    <MenuItem sx={{ py: 1.5, display: activePage === "/login" ? "none" : undefined, color: "primary.contrastText", backgroundColor: "primary.main" }} component="a" href="https://app.marssoftech.com/login">
                      <ListItemIcon sx={{ color: "primary.contrastText" }}>
                        <Login fontSize="small"></Login>
                      </ListItemIcon>
                      <ListItemText>
                        <nobr>
                          <b>Sign In/Up</b>
                        </nobr>
                      </ListItemText>
                    </MenuItem>
                    <Divider sx={{ my: 3, display: token ? "block" : "none" }} />
                  </>
                )}
                {pages.map((page) => (
                  <MenuItem sx={{ py: 1.5 }} key={page.section + "_drawer"} selected={activePage === page.path} onClick={page.section ? () => scrollToSection(page) : () => navigateTo(page)}>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText>{page.label}</ListItemText>
                  </MenuItem>
                ))}
              </Box>
              <Typography sx={{ p: 2 }} variant="caption" textAlign="left">
                Build: <b>{buildNumber}</b>
                <br />
                Date: <b>{buildDate}</b>
              </Typography>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
