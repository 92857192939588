import React from "react";
import { Container, Typography, Box, Link, CssBaseline } from "@mui/material";
import PageTitle from "./PageTitle";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ pt:"120px", mt: 0, mb: 0, minHeight: "calc(100vh - 360px)" }}>
      <CssBaseline />
      <Box my={0} textAlign={"left"}>
        <PageTitle title="Terms and Conditions"></PageTitle>
        <Typography my={4} variant="subtitle1" gutterBottom>
          Effective Date: <b>Aug 11, 2024</b>
        </Typography>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to www.marssoftech.com (the "Website"), operated by Mars Softech ("we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of our Website. By accessing or using our Website, you agree to be bound by these
            Terms.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            2. Use of the Website
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to use the Website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Website. Prohibited behavior includes harassing or causing distress or
            inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our Website.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            3. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content on this Website, including text, graphics, logos, images, and software, is the property of Mars Softech or its content suppliers and is protected by international copyright laws. Unauthorized use, reproduction, or
            distribution of the content is prohibited.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            4. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            Mars Softech will not be liable for any direct, indirect, incidental, or consequential damages arising out of or in connection with the use or inability to use the Website, even if we have been advised of the possibility of such
            damages.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            5. External Links
          </Typography>
          <Typography variant="body1" paragraph>
            Our Website may contain links to third-party websites that are not owned or controlled by Mars Softech. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.
            You access such sites at your own risk.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            6. Changes to These Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify these Terms at any time. Any changes will be posted on this page with an updated effective date. Your continued use of the Website following the posting of changes constitutes your acceptance of the revised Terms.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            7. Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by and construed in accordance with the laws of Jaipur, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="h6" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about these Terms and Conditions, please contact us at:
          </Typography>
          <Typography variant="body1">
            Mars Softech
            <br />
            [Jaipur]
            <br />
            <Link href="mailto:[Your Email Address]">[Email Address]</Link>
            <br />
            [+91 XXXXXXXXX]
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
