import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Source } from "@mui/icons-material";
import { Button } from "@mui/material";

export default function Hero() {
  return (
    <Container
      id="home"
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "100dvh",
        width: "100vw",
        minWidth: "100vw",
        position: "relative",
        marginLeft: "calc(-50vw + 50%)",
      }}
    >
      <div className="video-container">
        <video autoPlay muted loop playsInline className="background-video" src="/assets/bg_vid_bw.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <Stack spacing={2} sx={{ alignItems: "center", width: "100%" }}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            fontWeight: "bold",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            color: "white",
          }}
        >
          Market Anslysis & Research Solutions
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "white",
            width: { sm: "100%", md: "80%" },
          }}
        >
          Analyze the stocks quickly...
        </Typography>
        <Button color="primary" variant="contained" size="large" component="a" href="https://app.marssoftech.com/signup">
          <nobr>Register yourself for Free!</nobr>
        </Button>
      </Stack>
    </Container>
  );
}
