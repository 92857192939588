import React from "react";
import { Typography, Container, Link, CssBaseline, Stack } from "@mui/material";
import MarsLogo from "./MarsLogo";

export default function PageNotFound() {
  return (
    <Container maxWidth="md" sx={{ display: "flex", alignItems: "center", minHeight: "calc(100vh - 360px)", pt: 16, pb: 16 }}>
      <CssBaseline />
      <Stack spacing={2} sx={{ alignItems: "center", width: "100%" }}>
        <Typography variant="h4" sx={{ mt: 0, mb: 0 }} fontWeight="bold" align="center">
          Uh oh!
        </Typography>
        <Typography my={2} variant="body1">
          We're sorry that you landed on a wrong page.
        </Typography>
        <Link id="pnfHome" to="/" component="a" href="/">
          <MarsLogo width={100} height={100} />
          <Typography variant="h5" fontWeight={"bold"}>
            Go to Home
          </Typography>
        </Link>
      </Stack>
    </Container>
  );
}
