import { Container, CssBaseline, Divider, Link, Typography } from "@mui/material";
export default function Copyright({ gradientColor: Gradient }, props) {
  return (
    <Container
      className="copyright"
      sx={{
        mt: 0,
        padding: "0px !important",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: `radial-gradient(ellipse 50% 50% at 50% 110%, ${Gradient}, transparent)`,
      }}
    >
      <Divider></Divider>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 1, pb: 3 }} {...props}>
        <CssBaseline />
        {"Copyright © "} &nbsp;
        <Link sx={{ fontWeight: "bold" }} component="a" target="_blank" rel="noreferrer" href="https://marssoftech.com/">
          Mars Softech
        </Link>{" "}
        &nbsp;
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
}
