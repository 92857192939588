import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { Link, useTheme } from "@mui/material";

export default function MarsLogo() {
  let theme = useTheme();

  let class1 = theme.palette.mode === "dark" ? theme.palette.logo.top : theme.palette.logo.top;
  let class2 = theme.palette.mode === "dark" ? theme.palette.logo.bottom : theme.palette.logo.bottom;

  return (
    <Link href="/home" style={{ display: "flex" }}>
      <SvgIcon sx={{ height: { xs: 48, sm: 48 }, width: { xs: 100, sm: 100 }, mr: 0, paddingRight: { md: 0, xs: 2 } }}>
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.11 119.16">
          <g id="Layer_1-2">
            <path fill={class2} className="cls-1" d="M49.76,103.84l-5.23-3.84-3.31,17.58h12.78l4.24-22.39c.13-.71.29-1.39.45-2.05l-3.55-4.4-5.37,15.1Z" />
            <polygon fill={class2} className="cls-1" points="17.26 108.82 15.63 117.58 28.55 117.58 31.3 102.69 21.31 111.53 17.26 108.82" />
            <polygon fill={class2} className="cls-1" points="70.93 95.5 66.82 117.58 79.74 117.58 82.5 102.87 77.5 92.17 70.93 95.5" />
            <path fill={class2}
              className="cls-1"
              d="M135.93,99.22l-8.82-6.41c-2.93,8.38-7.35,12.32-11.24,12.32-3.3,0-5.2-2.1-5.99-5.43l-6.07,5.79-5.46-13.35-6.14,7.8c.37,11.29,6.7,18.88,16.5,18.88,6.28,0,13.36-3.19,18.62-12.52h.34c-.28,4.06-.57,7.88-.7,11.29h15.23c-.15-6.81.86-17.68,2.2-25.14l-8.45,6.77Z"
            />
            <path fill={class2} className="cls-1" d="M164.74,76.05l-3.71,3-7.31,38.53h20.33l5.39-28.69c.47-2.54,1.1-5,1.87-7.31l-10.6,4.85-5.96-10.38Z" />
            <path fill={class1}
              className="cls-2"
              d="M92.34,94.92l6.84-8.69,5.75,14.05,4.54-4.33c0-.15,0-.29,0-.44.04-10.39,7.44-26.13,18.4-26.13,1.49,0,3,.18,4.3.47l-3.1,15.56c-.29,1.55-.63,3-1.01,4.36l7.79,5.66,9.44-7.56,5.71-28.41c-4.6-1.53-11.74-2.83-18.52-2.83-26.33,0-38.6,19.79-40.14,38.28Z"
            />
            <path fill={class1}
              className="cls-2"
              d="M75.22,69.63c-5.82.02-11.32,2.83-15.2,8.39-.46-4.13-3.55-8.39-10.15-8.39-5.79,0-11.08,2.59-15.07,8.27h-.16l.74-7.3h-11.39c-.62,4.29-1.35,9.49-2.45,15.31l-3.67,19.71,3.19,2.14,11.13-9.84.48-2.61c1.66-9.11,6.31-14.99,10.28-14.99,2.97,0,3.92,2.21,3.92,5.09,0,1.78-.28,3.71-.67,5.76l-1.05,5.57,3.14,2.31,5.81-16.34,5.63,6.96c2.14-5.96,5.44-9.35,8.57-9.35,2.82,0,4.1,1.98,4.1,4.87,0,1.91-.3,3.99-.68,6.09l-.1.52,7.29-3.69,4.54,9.72,1.46-7.79c.52-2.82.92-6.52.92-8.49,0-7.39-3.78-11.91-10.61-11.91Z"
            />
            <path fill={class1}
              className="cls-2"
              d="M207.65,44.36c-1.14-.14-2.37-.21-3.65-.21-8.72,0-16.25,5.99-21.38,15.41h-.48c.55-4.85.89-9.39,1.24-13.93h-17.54c-.81,6.36-1.78,17.3-3.66,27.34l-.29,1.53,3.7-2.99,6.35,11.06,10.93-5c3.58-7.92,9.34-13.39,17.61-13.39,1.15,0,2.4.07,3.23.16l3.94-19.98Z"
            />
            <path fill={class1} className="cls-2" d="M221.47,54.47l11.06,13.1,5.13-6.34c-.93-1.25-1.41-2.56-1.41-4.01,0-4.88,4.37-7.96,10.99-7.96.03,0,.06,0,.09,0l13.12-16.21c-3.53-.85-7.86-1.39-12.8-1.39-20.86,0-35.22,11.9-35.22,28.73,0,1.9.28,3.71.78,5.43l8.26-11.35Z" />
            <path fill={class2}
              className="cls-1"
              d="M245.33,67c-2.27-1.24-4.13-2.42-5.56-3.61l-7.18,8.88-10.93-12.95-7.13,9.8c2.65,5.32,7.59,9.67,13.56,13.06,7.21,4.06,9.8,7,9.8,11.32s-3.57,8.11-10.34,8.17c-7.47.06-15.06-2.89-19.41-5.11l-5.78,16.71c4.36,3.17,13.46,5.88,23.23,5.88,21.39,0,36.7-10.41,36.7-29.44,0-10.16-7.7-17.27-16.94-22.71Z"
            />
            <path fill={class1} className="cls-2" d="M262.01,52.61l5.51-16.94c-1.07-.61-2.39-1.19-3.94-1.72l-12.57,15.54c4.59.52,8.46,1.87,11,3.11Z" />
            <polygon fill={class2} className="cls-1" points="17.86 105.57 3.22 94.56 0 99.37 16.71 111.77 17.86 105.57" />
            <path fill={class1} className="cls-2" d="M274.37,11.77l-16.53,9.3-.04.44,9.45,1.27-8.04,9.99c1.97.41,3.72.92,5.23,1.48l6.83-8.49,3.39,9.38.38-.17,5.64-18.11c2.81-5.62,5.62-11.25,8.43-16.87-4.91,3.92-9.83,7.85-14.74,11.77Z" />
          </g>
        </svg>
      </SvgIcon>
    </Link>
  );
}
