import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CssBaseline, Stack } from "@mui/material";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "100dvh",
      }}
    >
      <CssBaseline />
      <Stack spacing={2} sx={{ alignItems: "center", width: "100%" }}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            fontWeight: "bold",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
              <Typography component="h3" textAlign={"left"} variant="subtitle2">
                <b>Lorem Ipsum is simply dummy text of the printing and typesetting industry?</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" textAlign="left">
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                versions of Lorem Ipsum.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
    </Container>
  );
}
