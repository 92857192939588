import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";

import Copyright from "./Copyright";
import { Instagram, YouTube } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export default function Footer({ gradientColor }) {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);

  useEffect(() => {
    if (activePage !== location.pathname) {
      setActivePage(location.pathname);
    }
  }, [location, activePage]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 2,
          mt: 4,
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Stack
          direction={{
            sm: "column",
            md: "row",
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: { xs: 0, sm: 0 },
            width: "100%",
          }}
        >
          <div>
            <Link color={activePage === "/privacy" ? "primary" : "secondary"} variant="body2" href="/privacy" to="/privacy" component="a">
              Privacy Policy
            </Link>
            <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>&nbsp;•&nbsp;</Typography>
            <Link color={activePage === "/tnc" ? "primary" : "secondary"} variant="body2" href="/tnc" to="/tnc" component="a">
              Terms of Service
            </Link>
            <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>&nbsp;•&nbsp;</Typography>
            <Link color={activePage === "/disclaimer" ? "primary" : "secondary"} variant="body2" href="/disclaimer" to="/disclaimer" component="a">
              Disclaimer
            </Link>
            <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>&nbsp;•&nbsp;</Typography>
            <Link color={activePage === "/contact" ? "primary" : "secondary"} variant="body2" href="/contact" to="/contact" component="a">
              Contact Us
            </Link>
          </div>
          <Stack
            direction={{
              xs: "row",
              md: "row",
            }}
            spacing={1}
            useFlexGap
            sx={{ justifyContent: "left", color: "secondary.warning", mt: { xs: 2, md: 0 } }}
          >
            <IconButton color="inherit" href="https://facebook.com/marssoftech" aria-label="Facebook" sx={{ alignSelf: "center" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton color="inherit" href="https://youtube.com/@marssoftech" aria-label="Youtube" sx={{ alignSelf: "center" }}>
              <YouTube />
            </IconButton>
            <IconButton color="inherit" href="https://instagram.com/marssoftech" aria-label="Instagram" sx={{ alignSelf: "center" }}>
              <Instagram />
            </IconButton>
            <IconButton color="inherit" href="https://x.com/marssoftech" aria-label="X" sx={{ alignSelf: "center" }}>
              <TwitterIcon />
            </IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com/company/marssoftech/" aria-label="LinkedIn" sx={{ alignSelf: "center" }}>
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <Copyright gradientColor={gradientColor} />
    </>
  );
}
