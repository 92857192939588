import "./styles/App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { Container, ThemeProvider } from "@mui/material";
import LandingPage from "./LandingPage";
import AppAppBar from "./components/AppAppBar";
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import PageNotFound from "./components/PageNotFound";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";

function App() {
  const darkThemeOptions = {
    palette: {
      mode: "dark",
      logo: {
        bottom: "#ed2024",
        top: "#2db34a",
      },
      primary: {
        main: "#CBFF4D",
      },
      secondary: {
        main: "#999999",
      },
      info: {
        main: "#0288d1",
      },
      error: {
        main: "#FF0000",
      },
      warning: {
        main: "#5e2300",
      },
      success: {
        main: "#00900a",
      },
    },
  };

  const lightThemeOptions = {
    palette: {
      mode: "light",
      logo: {
        bottom: "#ed2024",
        top: "#2db34a",
      },
      primary: {
        main: "#059212",
      },
      secondary: {
        main: "#111111",
      },
      info: {
        main: "#0288d1",
      },
      error: {
        main: "#FF0000",
      },
      warning: {
        main: "#5e2300",
      },
      success: {
        main: "#00900a",
      },
    },
  };

  const darkTheme = createTheme(darkThemeOptions);
  const lightTheme = createTheme(lightThemeOptions);

  const [mode, setMode] = React.useState(localStorage.getItem("mode") || "light");

  const toggleColorMode = () => {
    setMode((prev) => {
      let mode = prev === "dark" ? "light" : "dark";
      localStorage.setItem("mode", mode);
      return mode;
    });
  };

  return (
    <Router>
      <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
        <Container
          className="App"
          /* sx={(theme) => ({
            backgroundRepeat: "no-repeat",
            //backgroundImage: `radial-gradient(ellipse 80% 400px at 50% -10%, ${(mode === "dark" ? darkTheme : lightTheme).palette.primary.main}, transparent)`,
            backgroundImage: `radial-gradient(ellipse 60% 400px at 50% -240px, ${(mode === "dark" ? darkTheme : lightTheme).palette.primary.main}, transparent)`,
            ...theme.applyStyles("dark", {
              backgroundImage: `radial-gradient(ellipse 60% 400px at 50% -240px, ${(mode === "dark" ? darkTheme : lightTheme).palette.primary.main}, transparent)`,
            }),
          })} */
        >
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode}></AppAppBar>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/about" element={<LandingPage />} />
            <Route path="/faq" element={<LandingPage />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/tnc" element={<TermsAndConditions />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer gradientColor={(mode === "dark" ? darkTheme : lightTheme).palette.primary.main} />
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
