import React, { useContext, useState } from "react";
import { TextField, Button, Grid, Container, Typography, Card, Select, MenuItem, Divider, FormHelperText, FormControl, InputLabel } from "@mui/material";
import { API_CONTACT_US_URL } from "../Constants";
import axios from "axios";
import { GlobalStateContext } from "./GlobalStateProvider";
import { Controller, useForm } from "react-hook-form";

export default function ContactForm() {
  const { email, firstName, lastName, mobile } = useContext(GlobalStateContext);
  const { control } = useForm();

  const [formValues, setFormValues] = useState({
    name: firstName || "" ? (firstName || "") + " " + lastName : "",
    subject: "",
    email: email || "",
    mobile: mobile || "",
    message: "",

    /* name: "",
    subject: "",
    email: "",
    mobile: "",
    message: "", */
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    try {
      const response = await axios.post(API_CONTACT_US_URL, formValues);

      if (response.data.success === 1) {
        console.log("Thank you so much for contacting us. We will reach you soon.");
      } else {
        console.log("There is a problem contacting us. Try again.");
      }
    } catch (err) {
      console.log(err.response?.data?.message || err.message || "There is a problem contacting us. Try again.");
    } finally {
    }
  };

  return (
    <Container maxWidth="md" sx={{ p: 0 }}>
      <Card sx={{ p: 4 }}>
        <Typography sx={{ mb: 2 }} variant="h6" textAlign="left" omponent="h1" gutterBottom>
          Contact / Feedback Form
        </Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField autoFocus fullWidth label="Name" name="name" value={formValues.name} onChange={handleChange} variant="outlined" required />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" error="">
                <InputLabel id="demo-simple-select-label">Subject</InputLabel>

                <Controller
                  name="subject"
                  control={control}
                  defaultValue=""
                  /* rules={{
                    required: BANK_REQUIRED,
                    validate: handleBankSelectValidation,
                    valueAsNumber: true,
                  }} */
                  helperText=""
                  render={({ field }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      value="1"
                      label="Subject"
                      onChange={(event) => {
                        field.onChange(event);
                        handleChange(event); // assuming this updates selectedBank
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        Select Subject
                      </MenuItem>
                      <Divider></Divider>
                      <MenuItem key="feedback" value="feedback">
                        Feedback
                      </MenuItem>
                      <MenuItem key="support" value="support">
                        Support / Bug Reporting
                      </MenuItem>
                      <MenuItem key="feature" value="feature">
                        Feature Request
                      </MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Email" name="email" value={formValues.email} onChange={handleChange} variant="outlined" required />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Mobile Number" name="mobile" value={formValues.mobile} onChange={handleChange} variant="outlined" required />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Message" name="message" value={formValues.message} onChange={handleChange} variant="outlined" multiline rows={4} required />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
}
